import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { Button, ButtonBase } from "../../UIKit/Button";
import Routes from "./Routes";
import { Badge } from "../../UIKit/Badge";
import { PaginatedTable } from "../../UIKit/Table";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { FormattedMessage, useIntl } from "react-intl";
import { DealersImportPageResponse, DealersProvider } from "./DealersProvider";
import { DealersImport, reportShouldBeAvailable } from "./Dealers";
import { ProgressBar } from "../../UIKit/ProgressBar";
import { CloudDownloadIcon } from "@heroicons/react/outline";
import { Markdown } from "../../UIKit/Markdown";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

interface DealersImportListProps {
  dealersImportProvider: DealersProvider;
}

export function DealersImportList(props: DealersImportListProps) {
  const importsPerPage = 30; // constrained by the API
  const history = useHistory();
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();

  const [startingAt, setStartingAt] = useState<number>(1);

  const {
    isFetching: listsAreFetching,
    data: dealersImportListPage,
  } = useQuery<DealersImportPageResponse>(
    "dealersImportList::all::page::" + startingAt,
    () => {
      return props.dealersImportProvider.findPaginatedDealersImports(
        (startingAt - 1) * importsPerPage + 1,
        importsPerPage
      );
    },
    { keepPreviousData: true, refetchInterval: 10000 } // To allow a smooth pagination
  );

  function loadDealersImportsSlice(number: number) {
    setStartingAt(number);
  }

  const intl = useIntl();

  if (!dealersImportListPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "catalogues.loading",
            defaultMessage: "Loading catalogs...",
          })}
        />
      </div>
    );
  } else {
    const dealersImports = dealersImportListPage.items;
    const dealersImportsStats = dealersImportListPage.stats;
    return (
      <>
        {dealersImports.length === 0 && startingAt === 1 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="no.dealersImport.yet"
                  defaultMessage="there is no dealers import yet"
                />
              </p>
              <p>
                <FormattedMessage
                  id="no.dealersImport.yet.invite"
                  defaultMessage="Your dealers would love to be listed!"
                />
              </p>
              <div className="pt-2">
                <Button
                  label={"import.first.dealersImport.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(Routes(clientId, supplierId).addDealersImport)
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2 mt-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="dealersImports.dealersImports"
                  defaultMessage="Dealers importations"
                />{" "}
                &gt;{" "}
                <FormattedMessage
                  id="dealersImports.count.plural"
                  defaultMessage="{num, plural, one {# importation} other {# importations}}"
                  values={{ num: dealersImportsStats.totalCount }}
                />
              </h2>
              {listsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "dealersImports.checking.dealersImports",
                      defaultMessage: "import verification",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<DealersImport>
              onPageChange={loadDealersImportsSlice}
              totalItemsCount={dealersImportsStats.totalCount || 0}
              itemsPerPage={importsPerPage}
              data={dealersImports}
              page={startingAt}
              columns={[
                {
                  label: intl.formatMessage({
                    id: "dealersImports.dealersImports",
                    defaultMessage: "Dealers importations",
                  }),
                  content: (di) => {
                    return (
                      <span>
                        {di.createdAt.setLocale(intl.locale).toLocaleString()}
                        <div
                          onClick={() => {
                            props.dealersImportProvider.downloadFile(
                              di.id,
                              forgeFilename(di)
                            );
                          }}
                          className="cursor-pointer text-xs"
                        >
                          <CloudDownloadIcon className="w-5 inline" />{" "}
                          {intl.formatMessage({
                            id: "download.my.file",
                            defaultMessage: "download my file",
                          })}
                        </div>
                      </span>
                    );
                  },
                },
                {
                  label: "status",
                  content: (c) => (
                    <Badge
                      label={intl.formatMessage({
                        id: "dealersImport.statuses." + c.status,
                        defaultMessage: c.status,
                      })}
                    />
                  ),
                },
                {
                  label: intl.formatMessage({
                    id: "dealersImport.progress",
                    defaultMessage: "completion",
                  }),
                  content: (c) => {
                    if (c.status === "processing" || c.status === "pending")
                      return <ProgressBar progress={c.progress} />;
                    return <ProgressBar progress={100} />;
                  },
                },
                {
                  label: intl.formatMessage({
                    id: "dealersImports.import.report",
                    defaultMessage: "Import report",
                  }),
                  content: (dealersImport) => {
                    if (reportShouldBeAvailable(dealersImport)) {
                      return (
                        <Link
                          to={Routes(
                            clientId,
                            supplierId
                          ).dealersConversionReport(dealersImport.id)}
                        >
                          <ButtonBase
                            size="tiny"
                            type="cancel"
                            label={intl.formatMessage({
                              id: "catalogues.display.report",
                              defaultMessage: "display the report",
                            })}
                          />
                        </Link>
                      );
                    }
                    return <span className="text-gray-400">en cours</span>;
                  },
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <div className="mr-4 inline">
                  <Button
                    type="secondary"
                    iconName="EyeIcon"
                    label={intl.formatMessage({
                      id: "dealers.list.all",
                      defaultMessage: "List all dealers",
                    })}
                    onClick={(_e) => {
                      history.push(Routes(clientId, supplierId).dealersList);
                    }}
                  />
                </div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "dealersImports.add.an.import",
                    defaultMessage: "Import dealers",
                  })}
                  onClick={(_e) => {
                    history.push(Routes(clientId, supplierId).addDealersImport);
                  }}
                />
              </div>
            </div>{" "}
            <div className={"prose max-w-none bg-gray-200 p-8 rounded my-8"}>
              <h3 className="flex items-center">
                <QuestionMarkCircleIcon className="w-6 mr-2" /> How does the
                dealers feature work in my application ?
              </h3>
              <div className="flex gap-8 ">
                <div className="w-5/12">
                  <Markdown>
                    {intl.formatMessage({
                      id: "dealers.explanation.1",
                      defaultMessage: `Your users may find the dealers in two ways :  
- **Search around me :  **
the app requests the use of the terminal's GPS  
- **Search around an address : ** 
the user provides a zip code, city or both`,
                    })}
                  </Markdown>
                </div>
                <div className="w-4/12">
                  <Markdown>
                    {intl.formatMessage({
                      id: "dealers.explanation.2",
                      defaultMessage: `In both cases, the app will display :
- **20 dealers** maximum
- located at **less than 300 km** from the provided address  
- **sorted by distance**`,
                    })}
                  </Markdown>
                </div>
                <div className="w-3/12">
                  <Markdown>
                    {intl.formatMessage({
                      id: "dealers.explanation.3",
                      defaultMessage: `If the user provides an address (or city, or zipcode), 
it is *geocoded* (transformed into latitude and longitude), 
and finally dealers are searched around these coordinates.`,
                    })}
                  </Markdown>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function forgeFilename(c: DealersImport) {
  return "dirbat_" + "dealers_" + c.id + ".xlsx";
}
